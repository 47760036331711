/**
 * Site header
 */
 body {
    font-family: 'Avenir Next', Avenir, Palatino;
}

.site-header {
    min-height: 60px;
    padding-top: 10px;
    position: relative; // Positioning context for the mobile navigation icon
}

.site-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }

    &:hover {
        text-decoration: none;
        opacity: 0.7;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        &:not(:last-child) {
            margin-right: 20px; // Gaps between nav items, but not on the last one
        }
    }

    @include media-query($on-phone) {
        position: absolute;
        top: 9px;
        right: calc($spacing-unit / 2);
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

/**
 * Page content
 */
.page-content {
    padding: 50px 0;

    strong {
        font-weight: 600;
    }
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    clear: both;

    li {
        float: left;
        margin-bottom: 18px;
        height: 60px;
        width: 230px;
    }

    .post-link {
        font-size: 15px;
    }

    h2 {
        margin-bottom: 0;
    }

    .post-cover {
        width: 30px;
        height: 45px;
        object-fit: cover;
    }

    @include media-query($on-laptop) {
        li {
            margin-bottom: 30px;
            width: 300px;
            height: 80px;
        }

        .post-cover {
            width: 50px;
            height: 70px;
        }
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 17px;
    color: #333;
    display: flex;
    align-items: center;

    &:visited {
        color: #333;
    }

    &:hover {
        text-decoration: none;
        opacity: 0.7;
    }

    img {
        margin-right: 10px;
        border-radius: 2px;
    }
}

/**
 * Posts
 */
.post {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.post-header {
    padding-bottom: 40px;
    margin-bottom: 10px;
    display: flex;
}

.post-cover-big {
    width: 80px;
    height: 120px;
    object-fit: cover;
    border-radius: 2px;
}

.post-title {
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;

    @include media-query($on-laptop) {
        font-size: 25px;
    }
}

.post-author,
.post-star,
.eta {
    margin-left: 20px;
    opacity: 0.5;
}

.duplicates {
    max-width: 24px;
    height: 20px;
    position: relative;
    top: -23px;
    left: 165px;
    color: white;
    background: #cc1a1a;
    text-align: center;
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
}

.hidden {
    visibility: hidden;
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    p,
    ul,
    ol {
        font-size: 16px;
        line-height: 1.8;
        margin-bottom: 40px;
    }

    p + ul,
    p + ol {
        margin-top: -35px;
    }

    ul {
        list-style: circle;
    }

    hr {
        margin-top: 60px;
        margin-bottom: 60px;
        border: 0 none;
        height: 1px;
        background: $hr-color;
        opacity: 0.5;
    }
}

.random-btn {
    display: inline-block;
    position: relative;
    top: 6px;
    width: 68px;
    height: 34px;
    background-image: url(../images/random-btn.png);
    background-size: contain;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .random-btn {
        background-image: url(../images/random-btn@2x.png);
    }
}

.darkmode-switch {
    float: right;
    margin-top: 10px;
    cursor: pointer;
    width: 68px;
    height: 34px;
    background-image: url(../images/light-switch.png);
    background-size: contain;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .darkmode-switch {
        background-image: url(../images/light-switch@2x.png);
    }
}

.darkmode {
    background: $background-color--darkmode;
    color: $text-color--darkmode;

    .site-title {
        color: $text-color--darkmode;
    }

    .site-header {
        border-bottom-color: $hr-color--darkmode;
    }

    hr {
        background: $hr-color--darkmode;
    }

    .post-list > li {
        border-bottom-color: $hr-color--darkmode;
    }

    .post-header {
        border-bottom-color: $hr-color--darkmode;
    }

    .post-link {
        color: #abb4c3;
    }

    blockquote {
        background-color: #20232b;
        border-color: #1c1e27;
    }

    .darkmode-switch {
        background-image: url(../images/light-switch--dark.png);
    }

    @media screen and (-webkit-min-device-pixel-ratio: 1.5) {
        .darkmode-switch {
            background-image: url(../images/light-switch--dark@2x.png);
        }
    }
}