@charset "utf-8";
@use "sass:color";

// Typography variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

// Spacing variables
$spacing-unit: 20px;

// Color variables
$text-color: #111;
$text-color--darkmode: #eee;
$background-color: #fdfdfd;
$background-color--darkmode: rgb(40, 44, 52);
$brand-color: #2a7ae2;
$grey-color: #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark: color.adjust($grey-color, $lightness: -25%);
$hr-color: #eee;
$hr-color--darkmode: #383c46;

// Layout variables
$content-width: 1400px;

// Breakpoints
$on-phone: 600px;
$on-laptop: 700px;

// Mixin for media queries
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "base", "layout", "syntax-highlighting";
